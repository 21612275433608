import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import OrganizationsApi from '@/services/api/organizations.api';
let ErrorPage = class ErrorPage extends Vue {
    constructor() {
        super(...arguments);
        this.code = this.$route.query.code;
    }
    async mounted() {
        if (!this.code || !this.$route.query.payload) {
            this.$router.push('/');
            return;
        }
        try {
            const payload = JSON.parse(this.$route.query.payload);
            if (payload.action === 'create') {
                const { message } = await OrganizationsApi.create({
                    name: payload.name,
                    type: payload.type,
                    code: this.code,
                });
                this.$toast.success(message);
            }
            else if (payload.action === 'update') {
                const { message } = await OrganizationsApi.refreshAuth(+payload.id, {
                    type: payload.type,
                    code: this.code,
                });
                this.$toast.success(message);
            }
        }
        catch (error) {
            this.$toast.error(error.message);
        }
        finally {
            this.$router.push('/');
        }
    }
};
ErrorPage = __decorate([
    Component({})
], ErrorPage);
export default ErrorPage;
